<template>
  <div class="family-tree-card-menu">
    <template v-if="showAddRelativeSubmenu">
      <div class="loading-message" v-if="relativesLoading">Fetching relatives...</div>
      <template v-else>
        <div v-if="canAddParents" class="flex-row" @click.stop="addParentClick"><span class="label">Parent</span></div>
        <div class="flex-row" @click="addSpouseClick"><span class="label">Spouse</span></div>
        <div class="flex-row" @click="addSiblingClick"><span class="label">Sibling</span></div>
        <div class="flex-row" @click="addChildClick"><span class="label">Child</span></div>
      </template>
    </template>
    <template v-else>
      <div v-if="showFullProfile" class="flex-row" @click.stop="fullProfileClick">
        <account-icon :size="iconsSize" title="Full Profile"></account-icon>
        <span class="label">Full Profile</span>
      </div>

      <div v-if="editable" class="flex-row" @click.stop="editClick">
        <pencil-icon :size="iconsSize" title="Quick Edit"></pencil-icon>
        <span class="label">Quick Edit</span>
      </div>

      <div class="flex-row" :class="{disabled: !searchByProfileEnabled}" @click.stop="clickSearchByProfile">
        <magnify-icon :size="iconsSize" title="Search by profile"></magnify-icon>
        <span class="label">Search by profile</span>
      </div>

      <div v-if="editableFull" class="flex-row" @click.stop="fullProfileEditClick">
        <account-edit-icon :size="iconsSize" title="Full Edit"></account-edit-icon>
        <span class="label">Full Edit</span>
      </div>

      <div v-if="showAddRelative" class="flex-row" @click.stop="addRelativeClick">
        <plus-icon :size="iconsSize" title="Add Relative"></plus-icon>
        <span class="label">Add Relative</span>
      </div>

      <div v-if="removable" class="flex-row" @click.stop="deletePersonClick">
        <delete-icon :size="iconsSize" title="Delete"></delete-icon>
        <span class="label">Delete</span>
      </div>
      <div v-if="showViewTree" class="flex-row" @click.stop="viewTreeClick">
        <eye-icon :size="iconsSize" title="View Tree"></eye-icon>
        <span class="label">View Tree</span>
      </div>
      <div v-if="showViewLineage" class="flex-row" @click.stop="viewLineageClick">
        <lineage-icon :size="iconsSize" title="View Lineage"></lineage-icon>
        <span class="label">View Lineage</span>
      </div>
      <div v-if="showViewRelationship" class="flex-row" @click.stop="viewRelationshipClick">
        <view-relationship-icon :size="iconsSize" title="View Relationship"></view-relationship-icon>
        <span class="label">View Relationship</span>
      </div>
    </template>
  </div>
</template>

<script>
import AccountIcon from 'vue-material-design-icons/Account';
import AccountEditIcon from 'vue-material-design-icons/AccountEdit';
import DeleteIcon from 'vue-material-design-icons/Delete';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import MagnifyIcon from 'vue-material-design-icons/Magnify';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import EyeIcon from 'vue-material-design-icons/Sitemap';
import ViewRelationshipIcon from 'vue-material-design-icons/TransitConnectionVariant';

import {CONTEXT_MENU_SECTIONS} from '@/components/modules/familyTree/constants';

const SECTIONS = Object.values(CONTEXT_MENU_SECTIONS);

export default {
  props: {
    sections: {
      type: Array,
      validator: value => value.every(item => SECTIONS.includes(item)),
      default: () => SECTIONS,
    },
    searchByProfileEnabled: {type: Boolean},
    showAddRelativeSubmenu: Boolean,
    relativesLoading: Boolean,
    canAddParents: Boolean,
  },
  computed: {
    iconsSize() {
      return 22;
    },
    showFullProfile() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
    },
    removable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.DELETE);
    },
    editable() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.EDIT);
    },
    editableFull() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
    },
    showAddRelative() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
    },
    showViewTree() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_TREE);
    },
    showViewLineage() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_LINEAGE);
    },
    showViewRelationship() {
      return this.sections.includes(CONTEXT_MENU_SECTIONS.VIEW_RELATIONSHIP);
    },
  },
  methods: {
    fullProfileClick(event) {
      this.$emit('click-full-profile', event);
    },
    editClick(event) {
      this.$emit('click-edit', event);
    },
    fullProfileEditClick(event) {
      this.$emit('click-full-profile-edit', event);
    },
    deletePersonClick(event) {
      this.$emit('click-delete', event);
    },
    viewTreeClick(event) {
      this.$emit('click-view-tree', event);
    },
    viewLineageClick(event) {
      this.$emit('click-view-lineage', event);
    },
    viewRelationshipClick(event) {
      this.$emit('click-view-relationship', event);
    },
    addRelativeClick(event) {
      this.$emit('click-add-relative', event);
    },
    addParentClick() {
      this.$emit('click-add-parent');
    },
    addSpouseClick() {
      this.$emit('click-add-spouse');
    },
    addSiblingClick() {
      this.$emit('click-add-sibling');
    },
    addChildClick() {
      this.$emit('click-add-child');
    },
    clickSearchByProfile() {
      this.$emit('click-search-by-profile');
    },
  },
  components: {
    PencilIcon,
    DeleteIcon,
    PlusIcon,
    EyeIcon,
    LineageIcon,
    AccountIcon,
    AccountEditIcon,
    ViewRelationshipIcon,
    MagnifyIcon,
  },
  name: 'familyTreeCardMenu',
};
</script>

<style lang="scss" scoped>
.family-tree-card-menu {
  .loading-message {
    color: $off-white;
    padding: 8px 12px;
    opacity: 0.8;
  }
  min-width: 160px;
  background-color: $power-red;
  border-radius: 4px;
  transition: all 100ms linear;

  .flex-row {
    display: flex;
    align-items: center;
  }

  .flex-row {
    padding: 8px 12px;
    position: relative;
    z-index: 22;
    color: $off-white;
    white-space: nowrap;
    cursor: pointer;

    .label {
      position: relative;
      top: 1px;
      margin-left: 12px;
    }

    &:hover {
      background-color: $active-color;
    }
    &:first-child {
      padding-top: 9px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      padding-bottom: 9px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  .flex-row.disabled {
    color: $primary-200;
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
  }
}
</style>
