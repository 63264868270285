<template>
  <div class="popover-action-button" @contextmenu.prevent.stop>
    <slot></slot>
    <span class="action-menu-label">{{ label }}</span>
    <span class="help-text" v-if="helpText">{{ helpText }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    helpText: String,
  },
};
</script>

<style lang="scss" scoped>
.popover-action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  user-select: none;

  // fix mobile longpress on card hovers context menu and highlights it on initial open
  &:hover,
  &.active {
    background-color: $link-color;
    color: $off-white;
  }
  &:active {
    background-color: $link-color !important;
    color: $off-white !important;
  }

  &.disabled {
    color: $neutral-300;
    cursor: not-allowed;
    &:hover,
    &.active {
      background-color: $background-light !important;
      color: $neutral-300 !important;
    }
  }

  .action-menu-label {
    top: 1px;
    margin-left: 10px;
    white-space: nowrap;
  }
}
</style>
